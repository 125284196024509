import RestHelperList from '../../lib/api/resthelperlist.class'
import constants from '../../lib/constants'

class Controller {
    constructor($scope, $controller, $uibModal, $stateParams, $httpParamSerializer,$http, AlertsService) {
        'ngInject';

        this.$uibModal = $uibModal;
        this.AlertService = AlertsService;
        $scope.filter = {
            reviewed_at: 'null',
            state: 'ACTIVE'
        };

        let options = {
                name: 'Enrollment',
                defaultValues: {},
                params: {
                    sort: { 'id': 'desc' },
                    usr_state: 'any', search: '',
                    'includes[]': ['user', 'user.profileFields','program']
                }
            }
        ;
        let em = $controller(RestHelperList);
        em.setup($scope, 'admin/enrollments', options);
        em.run();
        this.$http = $http;
        this.em = em;

        this.sortOptions = [
            { label: 'Last state change', field: 'createdAt' },
            { label: 'Role', field: 'primaryRole' }
        ];


    }

    getMentorshipExpectations(row) {
        return _.get(row, 'user.profileFields.mentorship_expectations.expectation');


    }

    approve(row) {
        row.reviewedAt = new Date();
        row.save();

    }

    reject(row) {
        const self = this;
        this.$http.post('admin/enrollments/'+row.id+'/reject')
            .then((result) => {

                this.em.refresh();
                self.AlertService.showConstant('success', 'Enrollment Rejected');
                }
            );
    }
}

const component = {
    template: require('./enrollmentsReview.component.html'),
    controller: Controller,
}

export default  component;
